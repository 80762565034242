
<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Date Time"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Employee Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Employee"
              >
                <v-select
                  v-model="user_id"
                  label="fullname"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="employeeOption"
                >
                  <template
                    #option="{  profile_image, username, fullname,position }"
                  >
                    <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ fullname }} {{ name !== 'All' ? ((position && position.name ? ('/' + position.name) : '')) : '' }}</span>

                    <span class="font-weight-bolder">/{{ username }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea v-model="remarks" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="7">
                <b-form-group>
                  <label>Total Hours</label>
                  <b-form-input v-model="total_hours" disabled />
                </b-form-group>
              </b-col>
              <b-col md="5" class="p-0 pl-2 d-flex align-items-center">
                <vue-apex-charts
                  height="120"
                  type="donut"
                  :options="earningsChart.chartOptions"
                  :series="series"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-card-code title="Details" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table
                class="table table-bordered"
                style="min-width: 15cm; max-height: 15cm"
              >
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Time</th>
                    <th>Hours</th>
                    <th>Work</th>
                    <th>Attachment</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, id) in details"
                    :key="id"
                    style="max-height: 100px"
                  >
                    <td>{{ id + 1 }}</td>
                    <td>
                      <div
                        class="d-flex justify-content-around align-items-center"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="'From Time'"
                        >
                          <flat-pickr
                            v-model="item.from"
                            class="form-control"
                            :config="{
                              noCalendar: true,
                              enableTime: true,
                              dateFormat: 'h:i K',
                              time_24hr: false,
                            }"
                            @input="handleTimeChange(item)"
                            style="background: transparent; width: 100px"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b class="m-1"> To </b>
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                          :name="'To Time'"
                        >
                          <flat-pickr
                            v-model="item.to"
                            @input="handleTimeChange(item)"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'h:i K',
                              time_24hr: false,
                            }"
                            style="background: transparent; width: 100px"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </td>
                    <td>
                      <b-form-input v-model="item.hours" disabled />
                    </td>
                    <td style="max-height: 50px">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="'work'"
                      >
                        <b-form-input
                          v-model="item.work"
                          @input="handleWork()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-form-file v-model="item.attachment" placeholder="Select Attachment"
                        drop-placeholder="Drop file here..." accept="image/*" @input="
                          handleFileChange($event, id,'dailyworking')
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td style="max-height: 50px">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        :name="'description'"
                      >
                        <b-form-textarea v-model="item.description" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import VueApexCharts from "vue-apexcharts";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BFormFile,
  BCol,
  BInputGroup,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

const $earningsStrokeColor1 = "#ea5455";
const $earningsStrokeColor2 = "#ff9f43";
const $earningsStrokeColor3 = "#00cfe8";
const $earningsStrokeColor4 = "#55DD92";
const $earningsStrokeColor5 = "#919191";
const $earningsStrokeColor6 = "#A9A2F6";
const $earningsStrokeColor7 = "#B4B9BF";
const $earningsStrokeColor8 = "#D0D4DB";

export default {
  components: {
    BBadge,
    VueApexCharts,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormFile,
    BAvatar,
    Attachment
  },
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      date: moment(new Date()).format("DD/MM/yyyy"),
      user_id: "",
      employeeOption: [],
      remarks: "",
      ifEdit: "",
      total_hours: null,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      id: "",
      flag: false,
      userData: {},
      series: [],
      details: [
        {
          from: null,
          to: null,
          work: "",
          hours: "00:00",
          description: "",
          attachment:''
        },
      ],
      earningsChart: {
        chartOptions: {
          title: {
            text: "", // Set your desired label here
            style: {
              fontSize: "24px",
              color: "#333",
            },
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: function (value) {
                // Use the "HH:mm" formatted data for tooltips
                const hours = Math.floor(value / 60);
                const mins = value % 60;
                return `${hours.toString().padStart(2, "0")}:${mins
                  .toString()
                  .padStart(2, "0")}`;
              },
            },
          },
          annotations: {
            points: [
              {
                x: "50%",
                y: "50%",
                marker: {
                  size: 0,
                },
                label: {
                  text: "", // You can leave this empty or put a default value
                  style: {
                    fontSize: "16px",
                  },
                },
              },
            ],
          },
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: [],
          stroke: { width: 0 },
          colors: [
            $earningsStrokeColor1,
            $earningsStrokeColor2,
            $earningsStrokeColor3,
            $earningsStrokeColor4,
            $earningsStrokeColor5,
            $earningsStrokeColor6,
            $earningsStrokeColor7,
            $earningsStrokeColor8,
          ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      let formattedTotal = "";
                      // eslint-disable-next-line radix
                      if (val > 60) {
                        const hours = Math.floor(val / 60);
                        const minutes = val % 60;
                        formattedTotal = `${hours
                          .toString()
                          .padStart(2, "0")}:${minutes
                          .toString()
                          .padStart(2, "0")}`;
                      } else {
                        formattedTotal =
                          "00:" + val.toString().padStart(2, "0");
                      }
                      return formattedTotal;
                    },
                  },
                  // total: {
                  //   show: true,
                  //   offsetY: 15,
                  //   label: "Total",
                  // },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Daily working") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/hr/dailyworking");
            }
          } else if (item.add !== 1) {
            this.$router.push("/hr/dailyworking");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async handleFileChange(e, index, type) {
      this.check = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.details[index].attachment = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    handleWork() {
      this.remarks = "";
      this.details.map((item, index) => {
        if (index == 0) {
          this.remarks += item.work;
        } else {
          this.remarks += `, ${item.work}`;
        }
      });
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath)
          ? image
          : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleTimeChange(item) {
      let startTime = moment(
        `2023-09-09 ${moment(item.from, "hh:mm A").format("HH:mm")}`
      );
      let endTime = moment(
        `2023-09-09 ${moment(item.to, "hh:mm A").format("HH:mm")}`
      );
      const duration = moment.duration(endTime.diff(startTime));
      const hoursDifference = duration.hours();
      const minutesDifference = duration.minutes();
      if (
        !isNaN(hoursDifference) &&
        hoursDifference >= 0 &&
        !isNaN(minutesDifference) &&
        minutesDifference >= 0
      ) {
        item.hours =
          (hoursDifference < 10 ? "0" + hoursDifference : hoursDifference) +
          ":" +
          (minutesDifference < 10
            ? "0" + minutesDifference
            : minutesDifference);
      } else {
        item.hours = "00:00";
      }
      let hr = 0;
      let min = 0;

      this.details.map((item, index) => {
        hr += item.hours ? item.hours.split(":")[0] * 1 : 0;
        min += item.hours ? item.hours.split(":")[1] * 1 : 0;
      });
      let totalMin = min;
      let total_hours = 0;

      if (min >= 60) {
        totalMin = min % 60;
        total_hours = parseInt(min / 60);
      }
      this.total_hours = `${(hr + total_hours)
        .toString()
        .padStart(2, "0")} : ${totalMin.toString().padStart(2, "0")}`;
      this.$forceUpdate();
    },
    removeRow(index) {
      this.details.splice(index, 1);
      if (this.details.length > 0) {
        this.handleTimeChange(this.details[0]);
      } else {
        this.total_hours = "00:00";
      }
      this.handleWork();
    },
    addRow() {
      this.details.push({
        from: null,
        to: null,
        hours: "00:00",
        description: "",
        attachment:'',
        work: "",
      });
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/get-Daily-Working-By-Id/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.date = item.date ? moment(item.date).format("DD/MM/yyyy") : null;
      this.user_id = item.employee;
      this.details = item.details;
      this.remarks = item.remarks;
      this.total_hours = item.total_hours;

      this.getPichartData();
    },
    getPichartData() {
      this.details.map((item) => {
        const inputTimeString = item.hours;
        const time = moment(inputTimeString, "HH:mm");
        const totalMinutes = time.hours() * 60 + time.minutes();
        this.series.push(totalMinutes);
        this.earningsChart.chartOptions.labels.push(item.work);
      });
      this.$forceUpdate();
    },
    init() {
      this.userData = JSON.parse(localStorage.getItem("userData"));
      if (this.userData.role == "admin" || (this.userData.position && this.userData.position.name =='HR')) {
        this.getAllEmp();
      } else {
        this.user_id = this.userData;
        this.getEmp();
      }
    },
    async getAllEmp() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleChild(item) {
      item.map((child) => {
        this.employeeOption.push(child);
        if (child.children.length > 0) {
          this.handleChild(child.children);
        }
      });
    },
    async getEmp() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.userData.id}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.employeeOption = [this.userData];
          response.data.data.map((item) => {
            this.employeeOption.push(item);
            if (item.children.length > 0) {
              this.handleChild(item.children);
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/hr/dailyworking");
    },
    submitForm(e) {
      const data = {
        date: this.date,
        user_id: this.user_id.id,
        remarks: this.remarks,
        details: this.details,
        total_hours: this.total_hours,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/daily-working/${this.$route.params.id}`
              : `${baseApi}/daily-working`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$router.push("/hr/dailyworking");
              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .catch((error) => {
              this.flag = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>
